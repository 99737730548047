<template>
  <div class="container">
    <div class="content">
      <!-- 搜索框 -->
      <div class="header-item">
        <el-input
          placeholder="请输入版本号"
          v-model="params.version"
          suffix-icon="el-icon-search"
        >
        </el-input>
      </div>
      <div class="header-item">
        <el-input
          placeholder="请输入发布人"
          v-model="params.name"
          clearable
          @clear="getList(1)"
          suffix-icon="el-icon-search"
        >
        </el-input>
      </div>

      <div class="header-item">
        <el-button type="primary" icon="el-icon-search" @click="getList(1)"
          >搜索</el-button
        >
      </div>
       <div class="header-item">
        <el-button type="primary"  icon="el-icon-refresh-right" @click="getList(1)">刷新</el-button>
      </div>
      <!-- 更新版本 -->
      <div style="float: right; margin-right: 10px">
        <el-button type="primary" icon="el-icon-plus" @click="goAddPage"
          >更新版本</el-button>
      </div>

      <!-- 版本列表及操作 -->
      <div>
        <el-table
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :data="dataList"
          @selection-change="selectHandle"
          :header-cell-style="{ 'background-color': 'rgba(249, 249, 249)' }"
        >

          <el-table-column label="版本号">
            <template slot-scope="scope">
              {{ scope.row.version || "无" }}
            </template>
          </el-table-column>

          <el-table-column label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.updated_at | dateFormat }}
            </template>
          </el-table-column>

          <el-table-column label="发布人">
            <template slot-scope="scope">
              {{ scope.row.name || "无" }}
            </template>
          </el-table-column>

          <!-- 查看内容，编辑，删除 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <span class="replace-button"  @click="viewContent(scope.row)"
                >查看内容
              </span>
           

              <span class="replace-button"  @click="goEditPage(scope.row)"
                >编辑</span>
              <span
                class="replace-button"
                @click="refuse(scope.row)"
                style="color: #ff0000"
                >删除</span
              >
            </template>
          </el-table-column>

        </el-table>
           <!-- 查看内容弹出框 -->
              <el-dialog
                title="版本内容"
                :visible.sync="viewVisible"
                :modal="true"
                :close-on-click-modal="false"
                 width="450px"
              >
              <div v-html="form.content "></div>
              </el-dialog>
        <!-- 分页 -->
        <div style="margin-top: 20px; overflow: hidden">
          <div style="float: right">
            <el-pagination
              @current-change="getList"
              layout="total, prev, pager, next, jumper"
              :background="true"
              :current-page="params.pageIndex"
              :page-size="params.pageNumber"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorage } from '@/js/Utils';
export default {
  data() {
    return {
      searchType: 0,
      params: {
        version: "",
        name: "",
        pageNumber: 8,
        pageIndex: 0,
      },
      dataList: [],
      total: 0,
      selectList: "", //当前选中的所以对象id
      viewVisible: false, //查看内容dialog
      form: {
        content: "",
        version: "",
      },
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    //获取列表
    async getList(page) {
      this.params.pageIndex = page;
      // console.log(this.params);
      if (this.params.version == "") delete this.params.version;
      if (this.params.name == "") delete this.params.name;
      let res = await this.$ZGManager.versionInfo(this.params);
      if (res.status !== 200) return;
      this.dataList = res.data.list;
      this.total = res.data.total_rows;
    },
    //选中之后
    selectHandle(val) {
      this.selectList = "";
      val.map((e) => {
        this.selectList += `${e.id},`;
      });
      this.selectList = this.selectList.substring(
        0,
        this.selectList.length - 1
      );
    },
    //删除弹出
    refuse(row) {
      let dialog = this.$confirm("此操作将永久删除该记录，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let params = {
            status:0
          }
          let res = await this.$ZGManager.versionInfoUpdate(row.id,params);
          if (res.status !== 200) return;
          console.log(res);
          this.$message.success("删除成功");
          this.getList(this.params.pageIndex);
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
      if (dialog.action != "confirm") return;
    },
    // 查看内容
    viewContent(row) {
      this.form.content = row.content;
      this.form.version = row.version;
      this.viewVisible = true;
    },
  
 
    //点击跳转更新版本
    goAddPage() {
      let newsPage = this.$router.resolve({
        path:'/qllcompany/add'
      });
      window.open(newsPage.href,'_blank');
    },
    goEditPage(row) {
      let newsPage = this.$router.resolve(  {
          path:`/qllcompany/add`,
          query:
          {
            id:row.id
          }
        });
      window.open(newsPage.href,'_blank');

     
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .header-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
  }

  ::v-deep .el-dialog {
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 1px 3px rgba(216, 211, 211, 0.05);
    .el-dialog__body {
      overflow-x: hidden;
    div {
      img {
        max-width: 400px;
      }
    }
}
  }
}
.replace-button {
  padding: 0 3px;
  color: #1a9afd;
  cursor: pointer;
}

</style>